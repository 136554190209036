import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../../components/Dashboard/DashboardWrapper';
import Layout from '../../components/layout';
import RequestFormSectionNew from '../../components/Sections/RequestFormSectionNew';
import {
  withAuthorization,
} from '../../components/Session';
import HeadData from "../../data/HeadData"

const RequestsPageBase = () => {
  return (
    <DashboardWrapper pageName='Requests Forms'>
      <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
        <div className="container mx-auto px-4">
          <RequestFormSectionNew />
        </div>
      </div>
    </DashboardWrapper>
  )
};

const condition = authUser => !!authUser;

const RequestsPage = compose(
  withAuthorization(condition),
)(RequestsPageBase);

const Requests = () => (
  <Layout>
    <HeadData title='Requests Forms' />
    <RequestsPage />
  </Layout>
)

export default Requests
