import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const ToAnotherPageCard = ({ cardTitle, internalLink, internalLinkPath, buttonLink, buttonName }) => {
  return (
    <div className="bg-gradient-to-r from-gray-900 to-red-700 shadow-lg rounded-md mb-8">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-red-100 sm:text-4xl lg:pr-12 2xl:pr-24">
          <span className="block leading-snug">{cardTitle ? cardTitle : "Page Name"}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md">
            {internalLink
              ?
              <Link to={internalLinkPath ? internalLinkPath : "/"}>
                <button className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-lg font-bold rounded-md text-red-900 tracking-wider bg-white hover:shadow-xl">
                  {buttonName ? buttonName : "Click Here"}
                </button>
              </Link>
              :
              <a href={buttonLink ? buttonLink : 'https://www.831b.com'} target="_blank" rel="noreferrer noopener">
                <button className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-lg font-bold rounded-md text-red-900 tracking-wider bg-white hover:shadow-xl">
                  {buttonName ? buttonName : "Click Here"}
                </button>
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

ToAnotherPageCard.propTypes = {
  cardTitle: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonName: PropTypes.string,
  internalLink: PropTypes.bool,
  internalLinkPath: PropTypes.string,
}

export default ToAnotherPageCard