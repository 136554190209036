import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer'

import ToAnotherPageCard from '../Common/ToAnotherPageCard';

const RequestFormSectionNew = () => {
  const data = useStaticQuery(graphql`
    query RequestFormSectionNewQuery {
      prismicRequests {
        data {
          title
          description {
            richText
          }
        }
      }
      prismicRequestFormsCobranding {
        data {
          title
          button_text
        }
      }
      prismicRequestFormsCosponsor {
        data {
          title
          button_text
        }
      }
      prismicRequestFormsProFormaCalculator {
        data {
          title
          button_text
        }
      }
    }
  `);

  const doc = data.prismicRequests
  const cb = data.prismicRequestFormsCobranding
  const cs = data.prismicRequestFormsCosponsor
  const pfc = data.prismicRequestFormsProFormaCalculator

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold">
          {doc.data.title}
        </h2>
        {doc.data.description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <div className="w-full max-w-screen-lg">
        <ToAnotherPageCard
        cardTitle={cb.data.title}
        internalLink
        internalLinkPath="/requests/cobranding"
        buttonName={cb.data.button_text}
        />

        <ToAnotherPageCard
          cardTitle={cs.data.title}
          internalLink
          internalLinkPath="/requests/cosponsor"
          buttonName={cs.data.button_text}
        />

        <ToAnotherPageCard
          cardTitle={pfc.data.title}
          internalLink
          internalLinkPath="/requests/pro-forma-calculator"
          buttonName={pfc.data.button_text}
        />
      </div>
    </>
  )
}

export default RequestFormSectionNew